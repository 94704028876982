.page-enter-div {
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #121212;
}

.cover-1 {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.cover-heading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80vw;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cover-name {
  color: white;
  font-size: 8vw;
  font-weight: 600;
  display: inline-block;
  font-family: "Major Mono Display", sans-serif;
}

.cover-dot {
  color: white;
  font-size: 8vw;
  font-weight: 600;
  padding-left: 20px;
  display: inline-block;
  font-family: "Roboto", sans-serif;
}

.cover-2 {
  background-color: #0f0f0f;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.cover-3 {
  background-color: #111111;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.cover-4 {
  background-color: #131313;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.welcome-sentence {
  height: 90%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#welcome-sentence01 {
  visibility: visible;
  opacity: 1;
}

.welcome-sentence p {
  color: white;
  font-size: 8.5vw;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

.welcome-sentence p:nth-of-type(1) {
  text-align: start;
  padding-left: 10%;
}

.welcome-sentence p:nth-of-type(2) {
  text-align: end;
  padding-right: 10%;
}

.welcome-sentence p:nth-of-type(3) {
  text-align: start;
  padding-left: 10%;
}

.welcome-sentence p:nth-of-type(4) {
  text-align: center;
  padding-left: 15%;
}

.welcome-sentence p span {
  font-family: "Major Mono Display", monospace;
  color: rgb(150, 149, 149);
  font-size: 8.65vw;
  font-weight: 550;
}

@media (min-width: 2000px) {
  .welcome-sentence p {
    font-size: 200px;
  }

  .welcome-sentence p span {
    font-size: 225px;
  }
}
