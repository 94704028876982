@font-face {
  font-family: "suicideSquadFont";
  src: url("./suicideSquadFont.ttf");
}

#about-me-outter-wrap {
  max-width: 86vw;
  margin-left: 5vw;
}

.paragraph-div {
  margin: 10vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease-out;
  will-change: transform;

  small {
    font-family: "Major Mono Display", monospace;
    font-size: 3vw;
  }

  .section-paragraph {
    width: 80%;
    color: white;
    font-weight: bolder;
    font-family: "raleway";
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  .section-paragraph.hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  .section-title {
    font-size: 10vw;
    color: lightcyan;
    letter-spacing: 0.5vw;
  }

  .section-name {
    font-size: 8.5vw;
  }

  .section-small-name {
    font-size: 7vw;
  }

  .section-text {
    line-height: 7.5vw;
    font-size: 6vw;
  }

  .cross-text {
    font-style: italic;
    text-decoration: line-through;
    color: grey;
  }

  .notion-text {
    color: lightsalmon;
  }

  .school-text {
    text-decoration: none;
    color: #d6001c;
    border-bottom: 0.3vw solid currentColor;
  }

  .thingspace-link {
    text-decoration: none;
    color: #d6001c;
    border-bottom: 0.25vw solid currentColor;
  }

  .minimalism-text {
    color: lightsalmon;
    font-family: "Major Mono Display", monospace;
    font-size: 5.5vw;
  }

  .level-up-text {
    text-transform: uppercase;
    color: lightsalmon;
    span:nth-of-type(1) {
      font-size: 3vw;
    }
    span:nth-of-type(2) {
      font-size: 3.4vw;
    }
    span:nth-of-type(3) {
      font-size: 3.8vw;
    }
    span:nth-of-type(4) {
      font-size: 4.2vw;
    }
    span:nth-of-type(5) {
      font-size: 4.6vw;
    }
    span:nth-of-type(6) {
      font-size: 5vw;
    }
    span:nth-of-type(7) {
      font-size: 5.4vw;
    }
    span:nth-of-type(8) {
      font-size: 5.8vw;
    }
    span:nth-of-type(9) {
      font-size: 6.2vw;
    }
    span:nth-of-type(10) {
      font-size: 6.6vw;
    }
    span:nth-of-type(11) {
      font-size: 7vw;
    }
  }

  .love-text {
    font-family: "suicideSquadFont";
    color: pink;
    font-weight: normal;
  }

  .awesome-text {
    font-family: "suicideSquadFont";
    font-weight: normal;
    color: pink;
    font-size: 10vw;
  }

  .more-section-text {
    font-size: 5.5vw;
  }

  .adj-text {
    color: grey;
    font-size: 5vw;
    font-family: "Major Mono Display", monospace;
  }

  .java-text {
    padding: 0 0.5vw;
    color: #c33;
  }

  .js-text {
    padding: 0 0.5vw;
    color: black;
    background-color: #fcdc00;
  }

  .express-text {
    padding: 0 0.5vw;
    color: #77b363;
  }

  .python-text {
    padding: 0 0.5vw;
    color: #2b5b84;
  }

  .angular-text {
    padding: 0 0.5vw;
    background-color: #c3002f;
  }

  .react-text {
    padding: 0 0.5vw;
    color: #61dafb;
  }

  .redux-text {
    padding: 0 0.5vw;
    color: #764abc;
  }

  .r-text {
    padding: 0 0.5vw;
    color: #1e62b2;
  }

  .go-text {
    padding: 0 0.5vw;
    color: #7fd5ea;
  }

  .important-text {
    color: red;
    padding: 0 0.5vw;
  }

  .done-text {
    color: red;
    padding: 0 0.5vw;
    text-decoration: line-through;
  }

  .work-section-title {
    font-size: 5.5vw;
  }

  .work-section-small-title {
    font-size: 5.5vw;
  }

  .work-company-name {
    .at-text {
      font-family: "Major Mono Display", monospace;
    }
    float: left;
    color: grey;
  }

  .work-time-text {
    float: right;
    color: grey;
    padding-right: 0.5vw;
  }

  .work-section-small-font {
    font-size: 4vw;
  }
  .work-company-name {
    .at-text {
      font-family: "Major Mono Display", monospace;
    }
    float: left;
    color: grey;
  }

  .work-time-text {
    float: right;
    color: grey;
    padding-right: 0.5vw;
  }

  .work-section-text {
    font-size: 4vw;
  }

  .work-section-row {
    display: flex;
    .work-section-dot {
      flex: 5%;
      font-size: 6vw;
    }
    .work-section-detail {
      flex: 95%;
      div:nth-of-type(1) {
        margin-top: 2vh;
      }
      .work-section-small-detail {
        display: flex;
        .work-section-small-detail-dot {
          margin: 0;
          flex: 5%;
        }
        .work-section-small-detail-text {
          font-size: 3vw;
          flex: 95%;
          margin-bottom: 1.5vh;
          .small-text {
            font-size: 1.5vw;
          }
        }
      }

      @media (max-width: 600px) {
        .work-section-small-detail-text {
          font-size: 4vw;
          .small-text {
            font-size: 3vw;
          }
        }
      }
    }
  }

  .tech-dot {
    color: blueviolet;
  }

  .name-text {
    font-family: "suicideSquadFont";
    color: lightsalmon;
    font-weight: normal;
  }

  .squad-text {
    font-weight: normal;
    font-family: "suicideSquadFont";
  }

  .contact-me-div {
    display: flex;
    .input-text-div {
      flex: 30%;
    }
    .input-box-div {
      padding-left: 3vw;
      flex: 60%;
    }
    .textbox-div {
      padding-left: 3vw;
      flex: 60%;
    }
  }

  .input-text {
    color: pink;
    font-weight: normal;
    font-family: "suicideSquadFont";
    font-size: 6vw;
  }

  .input-box {
    background: transparent;
    user-select: none;
    outline: none;
    border: none;
    border-bottom: 0.3vw pink solid;
    font-size: 5vw;
    color: white;
    font-family: "raleway";
    width: 43vw;
  }

  .text-area-box {
    background: transparent;
    user-select: none;
    outline: none;
    border: 0.3vw pink solid;
    font-size: 4.3vw;
    color: white;
    font-family: "raleway";
    width: 43vw;
  }
}

.error-text {
  float: right;
  color: red;
  font-weight: normal;
  font-family: "suicideSquadFont";
  font-size: 2.5vw;
}
.pink-color {
  color: pink;
  font-size: 3vw;
}

.button-div {
  cursor: pointer;
  float: right;
  margin-right: 8vw;
}

.sent-message-div {
  padding: 5vw 1vw 0vw 1vw;
  margin-right: 6vw;
}
.full-width {
  width: 100%;
}

.send-text {
  background-color: pink;
  color: black;
  font-weight: normal;
  font-family: "suicideSquadFont";
  font-size: 5vw;
  padding: 1vw 1vw 0vw 1vw;
  transition: all 0.5s ease-in;
}

.not-available {
  opacity: 0.5;
  transition: all 0.5s ease-in;
}

.send-animation {
  background-color: transparent;
  width: 10vw;
  height: 10vw;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.stalk-text {
  color: pink;
  font-size: 4vw;
  font-weight: normal;
  font-family: "raleway";
}

.social-media-row {
  display: inline-block;
}

.social-media-icon-wrapper {
  margin: 0 3vw 0vw 10vw;
  display: inline-block;
  lottie-player {
    width: 17vw;
    height: 17vw;
  }
}

.contatct-link {
  text-decoration: none;
}

.popup-content {
  margin: 0;
  background: black;
  width: 40vw;
  height: 40vw;
  padding: 0;
  border: none;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.qrcode {
  width: 40vw;
  height: 40vw;
}

@media (max-width: 800px) {
  .social-media-icon-wrapper {
    margin: 0 3vw 0vw 35vw;
    display: block;
    lottie-player {
      margin-top: 3vw;
      width: 25vw;
      height: 25vw;
    }
  }

  .popup-content {
    width: 60vw;
    height: 60vw;
  }

  .qrcode {
    width: 60vw;
    height: 60vw;
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.mt5vh {
  margin-top: 5vh;
}

.mb5vh {
  margin-bottom: 5vh;
}

.mt3vh {
  margin-top: 3vh;
}

.mt0vh {
  margin-top: 0vh;
}

.mb3vh {
  margin-bottom: 3vh;
}

.mt10vh {
  margin-top: 10vh;
}

.mt12vh {
  margin-top: 12vh;
}

$componentSize: 30vw;
$componentSizeSmaller: 400px;
$componentSizeLarger: 33vw;
$maskSize: 6vw;
$maskSizeSmaller: 80px;
$speed: 1.5s;
$borderOffset: 4px;
$backgroundColor: grey;
$animationProps: $speed linear infinite alternate;

@keyframes mask-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX($componentSize - $maskSize);
  }
}

@keyframes mask-inner-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-($componentSize - $maskSize));
  }
}

@keyframes mask-move-smaller-screen {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX($componentSizeLarger - $maskSize);
  }
}

@keyframes mask-inner-move-smaller-screen {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-($componentSizeLarger - $maskSize));
  }
}

@keyframes mask-inner-move-larger-screen {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-($componentSizeSmaller - $maskSizeSmaller));
  }
}

@keyframes mask-move-larger-screen {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX($componentSizeSmaller - $maskSizeSmaller);
  }
}

.focus {
  display: inline-block;
  font-size: 5.5vw;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  line-height: 1;
  position: relative;
  width: $componentSize;
  &:before {
    content: "Focusing";
    filter: blur(3px);
  }
  &:after {
    content: "";
    position: absolute;
    width: $maskSize;
    height: calc(100% + #{$borderOffset});
    top: -($borderOffset);
    left: 0;
    border-top: 2px solid;
    border-bottom: 2px solid;
    animation: mask-move $animationProps;
  }
  &--mask {
    overflow: hidden;
    position: absolute;
    width: $maskSize;
    height: 100%;
    top: 0;
    left: 0;
    background: $backgroundColor;
    animation: mask-move $animationProps;
    &-inner {
      animation: mask-inner-move $animationProps;
    }
  }
}

@media (max-width: 600px) {
  .focus {
    margin-left: 1vw;
    margin-right: 2.5vw;
    &:after {
      animation: mask-move-smaller-screen $animationProps;
    }
    &--mask {
      animation: mask-move-smaller-screen $animationProps;
      &-inner {
        animation: mask-inner-move-smaller-screen $animationProps;
      }
    }
  }
}

@media (min-width: 2000px) {
  .focus {
    font-size: 90px;
    margin-right: 80px;
    width: $componentSizeSmaller;
    &:after {
      animation: mask-move-larger-screen $animationProps;
    }
    &--mask {
      animation: mask-move-larger-screen $animationProps;
      &-inner {
        animation: mask-inner-move-larger-screen $animationProps;
      }
    }
  }
}

@media (min-width: 2000px) {
  
  #about-me-outter-wrap {
    max-width: 1700px;
    margin: auto;
  }

  .paragraph-div {
    small {
      font-size: 60px;
    }

    .section-title {
      font-size: 170px;
      letter-spacing: 15px;
    }

    .section-name {
      font-size: 160px;
    }

    .section-small-name {
      font-size: 140px;
    }

    .section-text {
      line-height: 160px;
      font-size: 120px;
    }

    .minimalism-text {
      font-size: 100px;
    }

    .level-up-text {
      span:nth-of-type(1) {
        font-size: 70px;
      }
      span:nth-of-type(2) {
        font-size: 75px;
      }
      span:nth-of-type(3) {
        font-size: 80px;
      }
      span:nth-of-type(4) {
        font-size: 90px;
      }
      span:nth-of-type(5) {
        font-size: 95px;
      }
      span:nth-of-type(6) {
        font-size: 100px;
      }
      span:nth-of-type(7) {
        font-size: 105px;
      }
      span:nth-of-type(8) {
        font-size: 110px;
      }
      span:nth-of-type(9) {
        font-size: 115px;
      }
      span:nth-of-type(10) {
        font-size: 120px;
      }
      span:nth-of-type(11) {
        font-size: 125px;
      }
    }

    .awesome-text {
      font-size: 150px;
    }

    .more-section-text {
      font-size: 100px;
    }

    .adj-text {
      font-size: 90px;
    }

    .work-section-title {
      font-size: 90px;
    }

    .work-section-small-title {
      font-size: 90px;
    }

    .work-section-small-font {
      font-size: 75px;
    }

    .work-section-text {
      font-size: 75px;
      line-height: 90px;
    }

    .work-section-row {
      .work-section-dot {
        font-size: 120px;
      }
      .work-section-detail {
        .work-section-small-detail {
          .work-section-small-detail-text {
            font-size: 50px;
            .small-text {
              font-size: 40px;
            }
          }
        }
      }
    }

    .input-text {
      font-size: 100px;
    }

    .input-box {
      width: 800px;
      font-size: 100px;
    }

    .text-area-box {
      width: 800px;
      font-size: 80px;
    }

    .error-text {
      font-size: 50px;
    }

    .pink-color {
      font-size: 70px;
    }

    .send-text {
      font-size: 80px;
      padding: 30px 30px 15px 30px
    }

    .button-div {
      margin-right: 6vw;
    }

    .stalk-text {
      font-size: 70px;
    }

    .social-media-icon-wrapper {
      margin: 0 60px 0vw 50px;
      lottie-player {
        width: 180px;
        height: 180px;
      }
    }
  }
}
