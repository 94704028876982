.progress {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in;
  z-index: 10;
}

.progress.active {
  visibility: visible;
  opacity: 1;
}

.bar {
  position: fixed;
  left: 15px;
  background: #e8dfd8;
  width: 3px;
  border-radius: 10px;
  overflow: hidden;
}

.bar__fill {
  display: block;
  background: #bd8044;
}

.point {
  position: fixed;
  color: #ac9585;
  cursor: pointer;
  display: block;
  left: 12px;
}
.point:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  transition: 0.3s ease;
}
.show-radius .point:before {
  background: rgba(0, 0, 0, 0.1);
}
.point--complete,
.point--active {
  color: #ba7c3f;
}

.bullet {
  z-index: 1;
  position: relative;
  background: #ac9585;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transition: 0.3s ease;
}
.point--complete .bullet,
.point--active .bullet {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 6px #bd8044;
}
.point--active .bullet {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 10px #bd8044;
}

@media (max-width: 800px) {
  .point--complete .bullet,
  .point--active .bullet {
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 0 0 3px #bd8044;
  }
  .point--active .bullet {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 0 0 5px #bd8044;
  }
}

.blur-section {
  left: 30px;
  position: fixed;
  width: 0;
  height: 100vh;
  backdrop-filter: blur(30px);
  transition: 0.3s ease;
}
.blur-section.active {
  width: 25rem;
  transition: 0.3s ease;
}

.label {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: -13px;
  left: 23px;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Major Mono Display", monospace;
  transition: 0.3s ease;
  span {
    font-size: 1rem;
    visibility: hidden;
    opacity: 0;
    font-family: "Open Sans", sans-serif;
  }
}

.label.active {
  top: -30px;
  font-size: 1.2rem;
  width: 25rem;
  span {
    font-size: 1.2rem;
    line-height: 2;
    visibility: visible;
    opacity: 1;
  }
}

.label.active:hover {
  color: pink;
}


@media (max-width: 800px) {
  .label {
    display: none;
  }
  .label.active {
    display: block;
    top: -20px;
    font-size: 0.7rem;
    span {
      font-size: 0.7rem;
    }
  }
  .blur-section {
    left: 25px;
    width: 0;
  }
  .blur-section.active {
    width: 15rem;
  }
}


.radius-toggle {
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
  background: #fff;
  border: 0;
  border-radius: 4px;
  box-shadow: 40px 0 65px rgba(212, 197, 186, 0.4);
  padding: 10px;
  color: #ba7c3f;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}
